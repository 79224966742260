import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import axios from 'axios';
import {central_data} from '@/lib/vendor/eve/central-data';
import U from '@/lib/vendor/eve/lib-utils';
central_data().ready(function () {
    axios.interceptors.response.use(function (response) {
        if (response.status === 401) {
            if (!/^\/login/.test(router.currentRoute.value.path)) {
                router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                return null;
            } else {
                response.data.status = 'error';
            }
        } else if (U.isObject(response.data)) {
            if (response.data.status === 'auth') {
                if (!/^\/login/.test(router.currentRoute.value.path)) {
                    router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                    return null;
                } else {
                    response.data.status = 'error';
                }
            }
        }
        //  console.log('login-incept:',response);
        return response;
    }, function (error) {
        if (401 === error.response.status) {
            if (!/^\/login/.test(router.currentRoute.value.path)) {
                router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                return;
            }

        }
        return Promise.reject(error);
    });
    window.Eve = window.Eve || {};
    window.Eve.ADVTable = window.Eve.ADVTable || {};
    window.Eve.ADVTable.Ready = window.Eve.ADVTable.Ready || [];
    window.Eve.ADVTable.Ready.push(function () {
        window.Eve.ADVTable.DataSource.DSXHR.registerResponseInterceptor(window, function (ds1, ds2, xhr) {
            if (xhr.status === 401) {
                if (!/^\/login/.test(router.currentRoute.value.path)) {
                    router.replace({path: '/login', query: {ret: router.currentRoute.value.fullPath}});
                    throw new Error('Требуется аутентификация');
                    //  return true;
                }
            }
            return false;
        });
        window.Eve.ADVTable.DataSource.DSXHR.registerHandler('error', window, function (dsxhr, resp) {
            var re = U.safeObject(resp);
            if (re.status === 'error') {
                var errorMessage = U.NEString(U.safeObject(re.error_info).message);
                re.error_info.message = central_data().translator.translate(errorMessage);
            }
        });
        createApp(App).use(router).use(central_data().translator).mount('#app');
    });
});

