import { createRouter, createWebHistory } from 'vue-router';
import {central_data} from '@/lib/vendor/eve/central-data';

const routes = [
    //<editor-fold defaultstate="collapsed" desc="mod-login">
    {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "mod-login" */ '@/components/login')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-user">    
    {
        path: '/user-list',
        name: 'user.list',
        component: () => import(/* webpackChunkName: "mod-user" */ '@/components/user-list'),
        children: [
            {
                path: 'add',
                name: 'user.add',
                component: () => import(/* webpackChunkName: "mod-user" */ '@/components/user-edit')
            },
            {
                path: 'edit/:userid',
                name: 'user.edit',
                props: true,
                component: () => import(/* webpackChunkName: "mod-user" */ '@/components/user-edit')
            }
        ]
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-client">    
    {
        path: '/client-list',
        name: 'client.list',
        component: () => import(/* webpackChunkName: "mod-client" */ '@/components/client-list'),
        children: [
            {
                path: 'edit/:clientId',
                name: 'client.edit',
                props: true,
                component: () => import(/* webpackChunkName: "mod-client" */ '@/components/client-edit')
            }
        ]
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-document">   
    {
        path: '/document-list',
        name: 'document.list',
        component: () => import(/* webpackChunkName: "mod-document" */ '@/components/document-list'),
        children: [
            {
                path: 'add',
                name: 'document.add',
                component: () => import(/* webpackChunkName: "mod-document" */ '@/components/document-edit')
            },
            {
                path: 'edit/:documentid',
                name: 'document.edit',
                props: true,
                component: () => import(/* webpackChunkName: "mod-user" */ '@/components/document-edit')
            }
        ]
    },
    {
        path: '/var-list',
        name: 'var.list',
        component: () => import(/* webpackChunkName: "mod-document" */ '@/components/var-list')
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-grant">    
    {
        path: '/grant-list',
        name: 'grant.list',
        component: () => import(/* webpackChunkName: "mod-grant" */ '@/components/grant-list')
        , children: [
            {
                path: 'add',
                name: 'grant.add',
                component: () => import(/* webpackChunkName: "mod-grant" */ '@/components/grant-edit')
            },
            {
                path: 'edit/:grantid',
                name: 'grant.edit',
                props: true,
                component: () => import(/* webpackChunkName: "mod-grant" */ '@/components/grant-edit')
            }
        ]
    },

    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-statement">
    {
        path: '/statement-list/:grantId',
        name: 'statement.list',
        props: true,
        component: () => import(/* webpackChunkName: "mod-statement" */ '@/components/statement-list')
        , children: [
            {
                path: 'edit/:statementId',
                name: 'statement.edit',
                props: true,
                component: () => import(/* webpackChunkName: "mod-statement" */ '@/components/statement-edit')
            }
        ]
    },
    //</editor-fold>
    //<editor-fold defaultstate="collapsed" desc="mod-preferences">    
    {
        path: '/preferences',
        name: 'preferences.list',
        component: () => import(/* webpackChunkName: "mod-preferences" */ '@/components/preferences')
    },
    //</editor-fold>
//    {
//        path: '/order-list',
//        name: 'order.list',
//        component: () => import(/* webpackChunkName: "mod-order" */ '@/components/order-list')
//        ,children: [            
//            {
//                path: 'edit/:orderid',
//                name: 'order.edit',
//                props: true,
//                component: () => import(/* webpackChunkName: "mod-order" */ '@/components/order-edit')
//            }
//        ]
//    },

    {
        path: '/',
        name: 'home',
        component: () => {
            return import('@/components/user-list');
        },
        props: true
    }
];
var route_id = 0;
const named_routes = routes.map(function (x) {
    if (!x.name) {
        x.name = ["rt", route_id].join('');
        route_id++;
    }
    return x;
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: named_routes
});
router.beforeResolve(function (a, b, c) {
    //console.log(arguments);
    c();
});


function reloadRoutes() {
    for (var iRoute = 0; iRoute < named_routes.length; iRoute++) {
        router.removeRoute(named_routes[iRoute].name);
    }
    for (var aRoute = 0; aRoute < named_routes.length; aRoute++) {
        router.addRoute(named_routes[aRoute]);
    }
    console.log('routes reloaded');
    //router.go();
    //console.log('force reload');
}

central_data().ready(function () {
    window.xxrouter = router;
    central_data().jwtMonitor.on({}, reloadRoutes);


});

export default router
