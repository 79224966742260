import U from './lib-utils';

var hGEM = null;

function prepare_event_name(x) {
    return U.NEString(U.NEString(x, '').toUpperCase(), null);
}

function get_object_identity(x) {
    if (U.is_object(x)) {
        if (U.is_callable(x['-lib-eve-utils-object-get-id'])) {
            return x['-lib-eve-utils-object-get-id']();
        } else {
            x['-lib-eve-event-manager-id'] = ['a', U.get_uuid().replace(/-/g, '')].join('');
            return x['-lib-eve-event-manager-id'];
        }
    }
    return 'n';
}

/**
 * @param {String} message  event mnemonic
 * @param {Object} context  callable context
 * @param {Function} callback callable
 * @param {Object|void} params additional params (not params of call)
 * @returns {event_message}
 */
// eslint-disable-next-line
function event_message(message, context, callback, params) {
    return (event_message.is(this) ? this.init : event_message.F).apply(this, Array.prototype.slice.call(arguments));
}

var E = U.fixup_constructor(event_message).prototype;

E.context = null;
E.callback = null;
E.params = null; // параметры привязанные к объекту события, не к событию
E.name = null;
E.hash = null;

E.init = function (message, context, callback, params) {
    this.name = prepare_event_name(message);
    this.context = U.select_object(context, callback);
    this.callback = U.select_callable(callback, context);
    if (!U.is_callable(this.callback)) {
        throw new Error('-lib-eve-event-event-message:callable required');
    }
    if (!this.name) {
        throw new Error('-lib-eve-event-event-message:event name required');
    }
    this.params = U.safe_object(params);
    this.hash = [
        get_object_identity(this.context),
        this.callback['-lib-eve-utils-function-get-id']()
    ].join(':');
    return this;
};

E.run = function () {
    var args = [this].concat(Array.prototype.slice.call(arguments));
    if (this.callback) {
        if (this.context) {
            this.callback.apply(this.context, args);
        } else {
            this.callback.apply(this, args);
        }
    }
};

E.destroy = function () {
    this.callback = null;
    this.context = null;
    return this;
};


function event_manager() {
    return (event_manager.is(this) ? this.init : event_manager.F).apply(this, Array.prototype.slice.call(arguments));
}

var M = U.fixup_constructor(event_manager).prototype;

M.permanent = null;
M.onetime = null;

M.init = function () {
    this.on_init();
    this.reset();
    return this;
};

M.on_init = function () {
    return this;
};

M.reset = function () {
    this.permanent = {};
    this.onetime = {};
    return this;
};

M.reset_event = function (e_name) {
    var n = prepare_event_name(e_name);
    if (n) {
        if (U.is_array(this.permanent[n])) {
            this.permanent[n] = [];
        }
        if (U.is_array(this.onetime[n])) {
            this.onetime[n] = [];
        }
    }
    return this;
};
M.clear_event = M.reset_event;


M.remove = function (e_name, context, callable) {
    var e = event_message(e_name, context, callable);
    return this.remove_event(e);
};

M.remove_event = function (event) {
    if (event_message.is(event)) {
        var sa = U.safe_array(this.permanent[event.name]);
        var nsa = [];
        for (var pIndex = 0; pIndex < sa.length; pIndex++) {
            if (sa[pIndex].hash === event.hash) {
                continue;
            }
            nsa.push(sa[pIndex]);
        }
        this.permanent[event.name] = nsa;
        var osa = U.safe_array(this.onetime[event.name]);
        var nosa = [];
        for (var oIndex = 0; oIndex < osa.length; oIndex++) {
            if (osa[oIndex].hash === event.hash) {
                continue;
            }
            nosa.push(osa[oIndex]);
        }
        this.onetime[event.name] = nosa;
    }
    return this;
};

/**
 * 
 * @param {String} e_name
 * @param {Object} context
 * @param {Function} callable
 * @param {Object|void} params
 * @returns {event_manager}
 */
M.on = function (e_name, context, callable, params) {
    var e = event_message(e_name, context, callable, params);
    return this.on_event(e);
};


/**
 * 
 * @param {event_message} event
 * @returns {event_manager}
 */
M.on_event = function (event) {
    if (event_message.is(event)) {
        if (!U.is_array(this.permanent[event.name])) {
            this.permanent[event.name] = [];
        }
        this.remove_event(event);
        this.permanent[event.name].push(event);
    } else {
        throw new Error('-lib-eve-event:on_event requires event object');
    }
    return this;
};
/**
 * 
 * @param {String} e_name
 * @param {Object} context
 * @param {Function} callable
 * @param {Object|void} params
 * @returns {event_manager}
 */
M.once = function (e_name, context, callable, params) {
    var e = event_message(e_name, context, callable, params);
    return this.once_event(e);
};

/**
 * 
 * @param {event_message} event
 * @returns {event_manager}
 */
M.once_event = function (event) {
    if (event_message.is(event)) {
        if (!U.is_array(this.onetime[event.name])) {
            this.onetime[event.name] = [];
        }
        this.remove_event(event);
        this.onetime[event.name].push(event);
    } else {
        throw new Error('-lib-eve-event:on_event requires event object');
    }
    return this;
};


M.run = function (e_name) {
    var n = prepare_event_name(e_name);
    if (n) {
        var args = Array.prototype.slice.call(arguments, 1); //
        var sa = U.safe_array(this.permanent[n]);
        for (var pIndex = 0; pIndex < sa.length; pIndex++) {
            try {
                sa[pIndex].run.apply(sa[pIndex], args);
            } catch (e) {
                window.setTimeout(function () {
                    throw e;
                }, 0);
            }
        }
        var sao = U.safe_array(this.onetime[n]);
        for (var oIndex = 0; oIndex < sao.length; oIndex++) {
            try {
                sao[oIndex].run.apply(sao[oIndex], args);
            } catch (e) {
                window.setTimeout(function () {
                    throw e;
                }, 0);
            }
        }
        this.onetime[n] = [];
    }
    return this;
};



function gem() {
    return hGEM ? hGEM : ((gem.is(this) ? this.init : gem.F).apply(this, Array.prototype.slice.call(arguments)));
}

var G = gem.leu_extend(event_manager).prototype;

G.on_init = function () {
    hGEM = this;
};

export {event_message, event_manager, gem};

